import React from 'react';
import { api_base_url } from '../../../environment'

interface Image {
  className?: string;
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  id?:string;
}

const ImageWithBaseURL = (props: Image) => {
  // Combine the base path and the provided src to create the full image source URL
  return (
    <img
      className={props.className}
      src={props.src}
      height={props.height}
      alt={props.alt}
      width={props.width}
      id={props.id}
    />
  );
};

export default ImageWithBaseURL;
