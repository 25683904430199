import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { get } from '../../connections/fetch_wrapper';
import { api_base_url, base_path } from '../../environment';
import ImageWithBaseURL from '../../core/data/img/ImageWithBaseURL';
import { Contact, Conversation } from '../../interfaces/message';

interface Props {
  gotoChat: (conversation: Conversation) => void;
}

const ContactsList: React.FC<Props> = ({gotoChat}) => {
  const [visible, setVisible]=useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [initialLetters, setInitialLetters] = useState<string[]>([]);
  const [visibleContacts, setVisibleContacts] = useState<Contact[]>([]);
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  console.log(isSmallScreen,"isSmallScreen")

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 992);
  };

  async function fetchData(){
    const response = await get(`/api/users?start=${start}&pagePer=${pageSize}`);
    if (response.code == 200) {
      console.log(response);
      
      setInitialLetters([...new Set(response.data.map(contact => contact.name.charAt(0)))]);
      setContacts(response.data);
    } else { 
    console.log(response);
    }
  }


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    fetchData();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function goto(filteredContact: Contact) {
    const conversation: Conversation = {
      conversation_type: 'INDIVIDUAL',
      sid: filteredContact.code,
      id: -1,
      name: '',
      sender: null,
      img: filteredContact.image,
      is_pinned: false,
      is_blocked: false,
      gsid: '',
      content: '',
      sent_at: '',
      status: '',
      is_now_online: false,
      type: null,
      file_type: null,
      blocked_by_you: filteredContact.blocked_by_me,
      blocked_by_me: filteredContact.blocked_by_you,
      mute: filteredContact.is_muted
    };
    console.log(conversation);
    
    gotoChat(conversation);
  }
    


  return ( <div className="sidebar-body chat-body" id="chatsidebar">
  {initialLetters.map(letter => (
      <div key={letter}>
        {/* Render section title */}
        <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
          <div className="fav-title contact-title">
            <h6>{letter}</h6>
          </div>
        </div>
        {/* Render contacts for this initial letter */}
        <ul className="user-list space-chat" key={'u'+letter}>
          {contacts.filter(contact => contact.name.charAt(0) === letter).map(filteredContact => (
            <li key={filteredContact.id} className="user-list-item">
              <Link to="#" onClick={()=>{goto(filteredContact)}}>
                <div className="avatar">
                  <ImageWithBaseURL src={filteredContact.image == '' || filteredContact.image == null ? `${base_path}assets/img/avatar/profile.jpg`:`${api_base_url}/${filteredContact.image}`} className="rounded-circle" alt="image" />
                </div>
                <div className="users-list-body">
                  <div>
                    <h5>{filteredContact.name}</h5>
                    {/* Additional details can be added here */}
                    <p>{filteredContact.email}</p>
                  </div>    
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>)
}

export default ContactsList