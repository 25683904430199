import React from "react";
import {  publicRoutes } from "./router.link";
import { Outlet, Route, Routes } from "react-router-dom";

const AllRoutes = () => {
  const HeaderLayout = () => (
    <>
      <Outlet />

    </>
  );
  // const AdminLayout = () => (
  //   <>
  //     <AdminHeader />
  //     <AdminSidebar />
  //     <Outlet />
  //     <ThemeSettings/>

  //   </>
  // );
  return (
    <>
      <Routes>
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
        {/* <Route path={"/admin/"} element={<AdminLayout />}>
          {adminRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route> */}

      </Routes>
    </>
  );
};
export default AllRoutes;

