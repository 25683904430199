

export const all_routes = {
  index: "/",
  contact: "/contact",
  group: '/group',
  settings:"/settings",
  register:"/register",
  resetPassword:"/reset-password",
  resetPasswordSuccess:"/reset-password-success",
  emptyStatus:"/empty-status",
  call:"/call",
  audioCall:"/audio-call",
  videoCall:"/video-call",
  callGrid:"/call-grid",
  emailLogin:"/email-login",
  viewStatus:"/view-status",
  status:"/status",
  statusOther:"/status-other",
  pinnedChat:"/chat/pinned-chat",
  allChat:"/chat/all-chat",
  archiveChat: "/chat/archive-chat",
  lockScreen:"/lock-screen",
  login:"/login",

  //admin
  // profileSettings:"/admin/profile-settings",
  // adminDashboard:"/admin/index",
  // websiteSettings:"/admin/website-settings",
  // systemSettings:"/admin/system-settings",
  // notificationSettings: "/admin/notification-settings",
  // localization: "/admin/localization",
  // storageSetting: "/admin/storage-setting",
  // socialAuth: "/admin/social-auth",
  // emailSettings: "/admin/email-settings",
  // authenticationSettings: "/admin/authentication-settings",
  // appearanceSettings: "/admin/appearance-settings",
  // smsSettings: "/admin/sms-settings",
  // language: "/admin/language",
  // gdpr: "/admin/gdpr",
  // banAddress:"/admin/ban-address",
  // otpSettings:"/admin/otp-settings",
  // languageAdmin:"/admin/language-admin",
  // languageApp: "/admin/language-app",
  // languageTranslate: "/admin/language-translate",
  // blankPage:"/admin/blank-page",
  // vectorMaps:"/admin/vector-maps",
  // components:"/admin/components",
  // basicInputs:"/admin/basic-inputs",
  // inputGroup:"/admin/input-group",
  // verticalForm:"/admin/form-vertical",
  // formMask:"/admin/form-mask",
  // formValidation:"/admin/form-validation",
  // basicTable:"/admin/basic-table",
  // dataTable:"/admin/data-table",
  
  // adminChat: "/admin/chat",
  // adminCall: "/admin/call",
  // stories: "/admin/stories",
  // usersList: "/admin/users",
  // horizontalForm :"/admin/form-horizontal",
  // blockUser: "/admin/block-user",
  // reportUser: "/admin/report-user",
  // inviteUser: "/admin/invite-user",
  };




