import React, { ChangeEvent, useEffect, useState, useRef } from "react"
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath"
import { Link } from "react-router-dom"
import { all_routes } from "../router/all_routes"
import { get, post, postFormData } from "../../connections/fetch_wrapper";
import TimeAgo from 'react-timeago'
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import { ChatData, Content, MsgResponse } from "../../interfaces/message";
import ChatList from "./message";
import { current } from "@reduxjs/toolkit";
import moment from "moment";
import ChatFooter from "./chat-footer";
import ContentInfoRightSideBar from "./content_info_right_side_bar";
import { localize } from "../../helpers/helpers";

interface Props {
  chatData: ChatData;
  receivedMsg: Content | null;
  isSmallChatOptions: boolean
  deleverdChat:ChatData|null;
  sendMsg: (message: MsgResponse) => void;
  contactInfoIconClick: () => void;
  msgInfoIconClick: (content: Content) => void;
  forwardInfoIconClick: (content: Content) => void;
}

const Chat: React.FC<Props> = ({ chatData, sendMsg, receivedMsg, contactInfoIconClick, msgInfoIconClick, forwardInfoIconClick, isSmallChatOptions,deleverdChat }) => {
  const chatRef = useRef<HTMLDivElement>(null);

  const routes = all_routes
  const [name, setName] = useState<string>();
  const [image, setImage] = useState<string | null>();
  const [myName, setMyName] = useState<string>();
  const [myImage, setMyImage] = useState<string | null>();
  const [lastSeen, setLastSeen] = useState<string | null>(null);
  const [isBlockedMe, setIsBlockedMe] = useState(false);
  const [isBlockedYou, setIsBlockedYou] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [type, setType] = useState<string>();
  const [sid, setSid] = useState<string>();
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [content, setContent] = useState<Content[]>([]);
  const [newContentId, setNewContentId] = useState<number>(-100000);
  const [replyMsg, setReplyMsg] = useState<Content | null>(null);



  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (deleverdChat != null) {
      if (chatData?.code == deleverdChat.code) {
        const updatedContent = content.map(item => {
          return { ...item, delivered_at: deleverdChat.deleverd_at ?? '' };
        });
        setContent(updatedContent);
      }
    }
  }, [deleverdChat]);


  useEffect(() => {
    scrollToBottom();
  }, [content]);

  useEffect(() => {
    if (receivedMsg != null) {
      const newContentArray = [...content, receivedMsg];
      setContent(newContentArray);
    }
  }, [receivedMsg]);


  useEffect(() => {
    setContent([]);
    if (chatData.type == 'GROUP') {
      fetchGroupDetails()
    } else {
      fetchUserDetails();
    }
  }, [chatData]);

  useEffect(() => {
    if (conversationId != null) {
      fetchMessages();
    }
  }, [conversationId]);

  async function fetchUserDetails() {
    console.log('hiiiiiiii');

    const response = await get(`/api/conversation/${chatData.code}/find-by-to-user`);
    if (response.code == 200) {
      setName(response.data.user.name);
      setImage(response.data.user.image);
      setLastSeen(response.data.user.last_online_at);
      setType('INDIVIDUAL');
      setSid(response.data.user.code);
      setIsBlockedMe(response.data.user.is_blocked_me);
      setIsBlockedYou(response.data.user.is_blocked_you);
      setIsDeleted(response.data.user.deleted_at);
      if (response.data.conversation != null) {
        setConversationId(response.data.conversation.id);
      }
      setMyName(response.data.user.name);
      setMyImage(response.data.user.image);
      console.log(response);

    } else {
      console.log(response);
    }
  }

  async function fetchGroupDetails() {
    const response = await get(`/api/conversation/${chatData.code}/find-by-group`);
    if (response.code == 200) {
      setName(response.data.group_info.name);
      setImage(response.data.group_info.img);
      setLastSeen(null);
      setType(response.data.conversation_info.type);
      setIsBlockedMe(false);
      setIsBlockedYou(false);
      setSid(response.data.group_info.sid);
      setIsDeleted(response.data.group_info.deleted_at);
      setConversationId(response.data.conversation_info.id);
      setMyName(response.data.group_info.my_name);
      setMyImage(response.data.group_info.my_image);
      console.log(response);
    } else {
      console.log(response);
    }
  }

  async function fetchMessages() {
    const response = await get(`/api/conversations/${conversationId}/messages`);
    if (response.code == 200) {
      console.log(response.data.data);

      setContent(response.data.data.reverse())
    } else {
      console.log(response);
    }
  }



  async function sendMessage(text: string | null, attachments: File[], attachmentImages: File[]) {
    if (text == '' && attachments.length == 0 && attachmentImages.length == 0) return;


    const formData = new FormData();
    formData.append('msg', text ?? '');
    formData.append('reply_msg_id', replyMsg?.id.toString() ?? '');
    if (type == 'INDIVIDUAL' && sid != null) {
      formData.append('to_user_sid', sid);
    } else if (type == 'GROUP' && sid != null) {
      formData.append('group_sid', sid);
    }
    attachments.forEach((file, index) => formData.append(`attachments[${index}]`, file));
    attachmentImages.forEach((file, index) => formData.append(`attachment_images[${index}]`, file));

    const currentId: number = newContentId;

    const newContent: Content = {
      id: currentId,
      content: text ?? '',
      status: "WAIT",
      sent_at: moment().format('YYYY-MM-DD hh:mm:ss'),
      sender: myName ?? '',
      img: myImage ?? '',
      type: "sent",
      attachments: [],
      file_type: attachments.length == 0 ? (attachmentImages.length == 0 ? null : 'image') : 'file',
      inital: true,
      reply_msg_id:replyMsg?.id,
      reply_file_type:replyMsg?.file_type,
      reply_content:replyMsg?.content,
      reply_user:replyMsg?.type == 'sent' ? 'you' : replyMsg?.sender,
      is_sent: true,
      is_deleverd: false,
      is_seen: false
    };

    const newContentArray = [...content, newContent];
    setContent(newContentArray);
    setReplyMsg(null);

    setNewContentId(currentId + 1);
    // setInputMsgValue('');

    const response = await postFormData(`/api/messages`, formData);
    if (response.code == 200) {
      sendMsg(response.data);
      const index = newContentArray.findIndex(item => item.id === currentId);
      newContent.status = "SENT";
      newContent.id = response.data.id;
      newContent.sent_at = response.data.sent_at;
      newContent.attachments = response.data.attachments;
      newContent.inital = false;

      // Create a new array with the updated message status
      const updatedContentArray = [...newContentArray.slice(0, index), newContent, ...newContentArray.slice(index + 1)];

      // Update the state with the new array
      setContent(updatedContentArray);
      console.log(response);
    } else {
      console.log(response);
    }
  }

  return (
    <div className="chat chat-messages" id="middle">
      <div>
        <div className="chat-header">
          <div className="user-details">
            <div className="d-lg-none">
              <ul className="list-inline mt-2 me-2">
                <li className="list-inline-item">
                  <Link
                    className="text-muted px-0 left_sides"
                    to="#"
                    data-chat="open"
                  >
                    <i className="fas fa-arrow-left" />
                  </Link>
                </li>
              </ul>
            </div>
            <figure className="avatar ms-1">
              <ImageWithBaseURL src={image == '' || image == null ? `${base_path}assets/img/avatar/profile.jpg` : `${api_base_url}/${image}`} className="rounded-circle" alt="image" />
            </figure>
            <div className="mt-1">
              <h5>{name}</h5>
              {lastSeen != null ? <small className="last-seen">Last Seen at <TimeAgo date={localize(lastSeen)} /></small> : <></>}

            </div>
          </div>
          <div className={`chat-options ${isSmallChatOptions ? "chat-small" : ""}`}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="btn btn-outline-light chat-search-btn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Search"
                >
                  <i className="bx bx-search" />
                </Link>
              </li>
              <li className="list-inline-item dream_profile_menu">
                <Link
                  to="#"
                  className="btn btn-outline-light not-chat-user"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Contact Info"
                  onClick={contactInfoIconClick}
                >
                  <i className="bx bx-info-circle" />
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  className="btn btn-outline-light no-bg"
                  to="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="bx bx-dots-vertical-rounded" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to={routes.index} className="dropdown-item ">
                    <span>
                      <i className="bx bx-x" />
                    </span>
                    Close Chat{" "}
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#mute-notification"
                  >
                    <span>
                      <i className="bx bx-volume-mute" />
                    </span>
                    Mute Notification
                  </Link>
                  {/* <Link
                        to="#"
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#disappearing-messages"
                      >
                        <span>
                          <i className="bx bx-time-five" />
                        </span>
                        Disappearing Message
                      </Link> */}
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#clear-chat"
                  >
                    <span>
                      <i className="bx bx-brush-alt" />
                    </span>
                    Clear Message
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#change-chat"
                  >
                    <span>
                      <i className="bx bx-trash" />
                    </span>
                    Delete Chat
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#report-user"
                  >
                    <span>
                      <i className="bx bx-dislike" />
                    </span>
                    Report
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#block-user"
                  >
                    <span>
                      <i className="bx bx-block" />
                    </span>
                    Block
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          {/* Chat Search */}
          <div className="chat-search">
            <form>
              <span className="form-control-feedback">
                <i className="bx bx-search" />
              </span>
              <input
                type="text"
                name="chat-search"
                placeholder="Search Chats"
                className="form-control"
              />
              <div className="close-btn-chat">
                <span className="material-icons">close</span>
              </div>
            </form>
          </div>
          {/* /Chat Search */}
        </div>
        <div className="chat-body chat-page-group slimscroll" ref={chatRef}>
          <ChatList messages={content} msgInfoIconClick={msgInfoIconClick} forwardInfoIconClick={forwardInfoIconClick}  replyInfoIconClick={(val) => { setReplyMsg(val) }} />
        </div>
      </div>
      <ChatFooter sendMsg={sendMessage} replyMsg={replyMsg} clearReplyMsg={() => { setReplyMsg(null) }}/>
    </div>
  )
}

export default Chat
