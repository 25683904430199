export  function getToken() {
        return localStorage.getItem('token');
}

export  function setToken(token: string) {
        localStorage.setItem('token', token);
}

export  function setUser(image: string | null,name:string,code:string) {
        localStorage.setItem('user', JSON.stringify({image,name,code}));
}

export  function getUser() {
        const storedUserString = localStorage.getItem('user');
        const user = storedUserString ? JSON.parse(storedUserString) : null;
        return user
}

export function localize(t:string){
        const d=new Date(t+" UTC");
        return d.toString();
}