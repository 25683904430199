import axios from "axios";
import { getToken } from "../helpers/helpers";
import { api_base_url } from "../environment";

export const axiosInstance = axios.create({
        baseURL: api_base_url,
        headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
        },
        // params: {
        //         lang: getLang()
        // },

});

axiosInstance.interceptors.request.use(
        async (config) => {
                const token =  getToken();
                if (token) {
                        config.headers.Authorization = `Bearer ${token}`
                }
                return config;
        },
        error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(undefined, err => {
        const status = err.response ? err.response.status : null;
        if (status === 401) {
                // logout();
        }
        return Promise.reject(err);
});


export const axiosInstanceFormData = axios.create({
        baseURL: api_base_url,
        headers: {
                "Content-type": "multipart/form-data"
        },
        // params: {
        //         lang: getLang()
        // },
});

axiosInstanceFormData.interceptors.request.use(
        async (config) => {
                const token =  getToken();
                if (token) {
                        config.headers.Authorization = `Bearer ${token}`
                }
                return config;
        },
        error => Promise.reject(error)
);

axiosInstanceFormData.interceptors.response.use(undefined, err => {
        const status = err.response ? err.response.status : null;
        if (status === 401) {
                // logout();
        }
        return Promise.reject(err);
});


export const axiosInstanceServer = axios.create({
        baseURL: api_base_url,
        headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
        },
        // params: {
        //         lang: getLang()
        // },

});

axiosInstanceServer.interceptors.request.use(
        async (config) => {
                const token =  getToken();
                if (token) {
                        config.headers.Authorization = `Bearer ${token}`
                }
                return config;
        },
        error => Promise.reject(error)
);

axiosInstanceServer.interceptors.response.use(undefined, err => {
        const status = err.response ? err.response.status : null;
        if (status === 401) {
                // logout();
        }
        return Promise.reject(err);
});