import React from "react"
import { Link } from "react-router-dom"
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath"
import { all_routes } from "../router/all_routes";
const routes = all_routes;


interface Props {
    isShowRightSidebar: boolean;
}

const ContentInfoRightSideBar: React.FC<Props> = ({ isShowRightSidebar}) => {
return (<div
    className={`right-sidebar right_sidebar_profile right-side-contact ${isShowRightSidebar ? "show-right-sidebar" : "hide-right-sidebar"}`}
    id="middle1"
  >
    <div className="right-sidebar-wrap active">
      <div className="slimscroll">
        <div className="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
          <div className="fav-title mb-0">
            <h6>Contact Info</h6>
          </div>
          <div className="contact-close_call text-end">
            <Link to="#" className="close_profile close_profile4">
              <span className="material-icons">close</span>
            </Link>
          </div>
        </div>
        <div className="sidebar-body">
          <div className="mt-0 right_sidebar_logo">
            <div className="text-center right-sidebar-profile">
              <figure className="avatar avatar-xl mb-3">
                <ImageWithBasePath
                  src="assets/img/avatar/avatar-2.jpg"
                  className="rounded-circle"
                  alt="image"
                />
              </figure>
              <h5 className="profile-name">Mark Villiams</h5>
              <div className="last-seen-profile">
                <span>last seen at 07:15 PM</span>
              </div>
              <div className="chat-options chat-option-profile">
                <ul className="list-inline">
                  <li
                    className="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Voice Call"
                  >
                    <Link
                      to={routes.audioCall}
                      className="btn btn-outline-light "
                      data-bs-toggle="modal"
                      data-bs-target="#voice_call"
                    >
                      <i className="bx bx-phone" />
                    </Link>
                  </li>
                  <li
                    className="list-inline-item "
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Video Call"
                  >
                    <Link
                      to={routes.videoCall}
                      className="btn btn-outline-light profile-open"
                      data-bs-toggle="modal"
                      data-bs-target="#voice_call"
                    >
                      <i className="bx bx-video" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={routes.index}
                      className="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Chat"
                    >
                      <i className="bx bx-message-square-dots" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="chat-member-details">
              <div className="member-details">
                <ul>
                  <li>
                    <h5>Info</h5>
                  </li>
                  <li>
                    <h6>Bio</h6>
                    <span>Hello, I am using DreamsChat</span>
                  </li>
                  <li>
                    <h6>Phone</h6>
                    <span>555-555-21541</span>
                  </li>
                  <li>
                    <h6>Email Address</h6>
                    <span>info@example.com</span>
                  </li>
                  <li>
                    <h6>Social Media</h6>
                    <div className="social-icons ">
                      <Link
                        to="https://www.facebook.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="bx bxl-facebook" />
                      </Link>
                      <Link
                        to="https://twitter.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="bx bxl-twitter" />
                      </Link>
                      <Link
                        to="#"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="bx bxl-youtube" />
                      </Link>
                      <Link
                        to="https://in.linkedin.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="bx bxl-linkedin" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="right-sidebar-head share-media">
          <div className="share-media-blk">
            <h5>Shared Media</h5>
            <Link to="#">View All</Link>
          </div>
          <div className="about-media-tabs">
            <nav>
              <div className="nav nav-tabs " id="nav-tab">
                <Link
                  className="nav-item nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  to="#info"
                >
                  Photos
                </Link>
                <Link
                  className="nav-item nav-link"
                  id="nav-profile-tab1"
                  data-bs-toggle="tab"
                  to="#Participants"
                >
                  Videos
                </Link>
                <Link
                  className="nav-item nav-link"
                  id="nav-profile-tab2"
                  data-bs-toggle="tab"
                  to="#media"
                >
                  File
                </Link>
                <Link
                  className="nav-item nav-link"
                  id="nav-profile-tab3"
                  data-bs-toggle="tab"
                  to="#link"
                >
                  Link
                </Link>
              </div>
            </nav>
            <div className="tab-content pt-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="info">
                <ul className="nav share-media-img mb-0">
                  <li>
                    <Link
                      to="assets/img/media/media-big-01.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-01.jpg"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="assets/img/media/media-big-02.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-02.jpg"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="assets/img/media/media-big-03.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-03.jpg"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="assets/img/media/media-big-04.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-04.jpg"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="assets/img/media/media-05.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-05.jpg"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li className="blur-media">
                    <Link
                      to="assets/img/media/media-02.jpg"
                      data-fancybox="gallery"
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-02.jpg"
                        alt=""
                      />
                    </Link>
                    <span>+10</span>
                  </li>
                </ul>
              </div>
              <div className="tab-pane fade" id="Participants">
                <ul className="nav share-media-img mb-0">
                  <li>
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-01.jpg"
                        alt="img"
                      />
                      <span>
                        <i className="bx bx-play-circle" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-02.jpg"
                        alt="img"
                      />
                      <span>
                        <i className="bx bx-play-circle" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-03.jpg"
                        alt="img"
                      />
                      <span>
                        <i className="bx bx-play-circle" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-04.jpg"
                        alt="img"
                      />
                      <span>
                        <i className="bx bx-play-circle" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-05.jpg"
                        alt="img"
                      />
                      <span>
                        <i className="bx bx-play-circle" />
                      </span>
                    </Link>
                  </li>
                  <li className="blur-media">
                    <Link
                      to="https://www.youtube.com/embed/Mj9WJJNp5wA"
                      data-fancybox
                      className="fancybox"
                    >
                      <ImageWithBasePath
                        src="assets/img/media/media-03.jpg"
                        alt="img"
                      />
                    </Link>
                    <span>+10</span>
                  </li>
                </ul>
              </div>
              <div className="tab-pane fade" id="media">
                <div className="media-file">
                  <div className="media-doc-blk">
                    <span>
                      <i className="bx bxs-file-doc" />
                    </span>
                    <div className="document-detail">
                      <h6>Landing_page_V1.doc</h6>
                      <ul>
                        <li>12 Mar 2023</li>
                        <li>246.3 KB</li>
                      </ul>
                    </div>
                  </div>
                  <div className="media-download">
                    <Link to="#">
                      <i className="bx bx-download" />
                    </Link>
                  </div>
                </div>
                <div className="media-file">
                  <div className="media-doc-blk">
                    <span>
                      <i className="bx bxs-file-pdf" />
                    </span>
                    <div className="document-detail">
                      <h6>Design Guideless.pdf</h6>
                      <ul>
                        <li>12 Mar 2023</li>
                        <li>246.3 KB</li>
                      </ul>
                    </div>
                  </div>
                  <div className="media-download">
                    <Link to="#">
                      <i className="bx bx-download" />
                    </Link>
                  </div>
                </div>
                <div className="media-file">
                  <div className="media-doc-blk">
                    <span>
                      <i className="bx bxs-file" />
                    </span>
                    <div className="document-detail">
                      <h6>sample site.txt</h6>
                      <ul>
                        <li>12 Mar 2023</li>
                        <li>246.3 KB</li>
                      </ul>
                    </div>
                  </div>
                  <div className="media-download">
                    <Link to="#">
                      <i className="bx bx-download" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="link">
                <div className="media-link-grp">
                  <div className="link-img">
                    <Link to="#">
                      <ImageWithBasePath
                        src="assets/img/media-link-01.jpg"
                        alt="Img"
                      />
                    </Link>
                  </div>
                  <div className="media-link-detail">
                    <h6>
                      <Link to="#">
                        Digital Marketing Guide
                      </Link>
                    </h6>
                    <span>
                      <Link to="#">
                        https://elements.envato.com/all-items/blog
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="media-link-grp mb-0">
                  <div className="link-img">
                    <Link to="#">
                      <ImageWithBasePath
                        src="assets/img/media-link-02.jpg"
                        alt="Img"
                      />
                    </Link>
                  </div>
                  <div className="media-link-detail">
                    <h6>
                      <Link to="#">Blog Post</Link>
                    </h6>
                    <span>
                      <Link to="#">
                        https://elements.envato.com/blog-post-TXQ5FB8
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-comman-theme">
          <h6>2 Group in Common</h6>
          <ul>
            <li>
              <div className="avatar">
                <ImageWithBasePath
                  src="assets/img/avatar/avatar-14.png"
                  className="rounded-circle"
                  alt="image"
                />
              </div>
              <div className="theme-content">
                <h6>Themeforest Group</h6>
                <p>Mark Villiams, Elizabeth, Michael....</p>
              </div>
            </li>
            <li>
              <div className="avatar">
                <ImageWithBasePath
                  src="assets/img/avatar/avatar-15.png"
                  className="rounded-circle"
                  alt="image"
                />
              </div>
              <div className="theme-content">
                <h6>Graphic Designers</h6>
                <p>Mark Villiams, Elizabeth, Michael....</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="chat-message-grp">
          <ul>
            <li>
              <Link to="#" className="star-message-left">
                <div className="stared-group">
                  <span className="star-message">
                    {" "}
                    <i className="bx bxs-star" />
                  </span>
                  <h6>Starred Messages</h6>
                </div>
                <div className="count-group">
                  <span>10</span>
                  <i className="feather-chevron-right" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#mute-notification"
              >
                <div className="stared-group">
                  <span className="mute-message">
                    {" "}
                    <i className="bx bxs-microphone-off" />
                  </span>
                  <h6>Mute Notifications</h6>
                </div>
                <div className="count-group">
                  <i className="feather-chevron-right" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#block-user"
              >
                <div className="stared-group">
                  <span className="block-message">
                    {" "}
                    <i className="bx bx-block" />
                  </span>
                  <h6>Block User</h6>
                </div>
                <div className="count-group">
                  <i className="feather-chevron-right" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#report-user"
              >
                <div className="stared-group">
                  <span className="report-message">
                    {" "}
                    <i className="bx bx-dislike" />
                  </span>
                  <h6>Report User</h6>
                </div>
                <div className="count-group">
                  <i className="feather-chevron-right" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete-user"
              >
                <div className="stared-group">
                  <span className="delete-message">
                    {" "}
                    <i className="bx bx-trash" />
                  </span>
                  <h6>Delete Chat</h6>
                </div>
                <div className="count-group">
                  <i className="feather-chevron-right" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>)
}

export default ContentInfoRightSideBar;
