import React from 'react'
import Feature from './feature';

const AppSection = () => {

   
  return (
    <div>
        <Feature/>

    </div>
  )
}

export default AppSection