import React, { useEffect, useState } from "react"
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath"
import { Link } from "react-router-dom"
import Contact from "../contact/contact";
import { Content, MsgDetailsResponse } from "../../interfaces/message";
import { get } from "../../connections/fetch_wrapper";
import moment from "moment";
import { localize } from "../../helpers/helpers";
import { api_base_url } from "../../environment";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";

interface Props {
  isShowRightSidebar: boolean;
  onCloseSideBar: () => void;
  messageDetails : Content|null;
}
const MessageInfo: React.FC<Props> = ({ isShowRightSidebar,onCloseSideBar,messageDetails}) => {
  const [msgDetails, setMsgDetails] = useState<MsgDetailsResponse | null>(null);
  
  useEffect(()=>{
    if (isShowRightSidebar == true && messageDetails != null) {
      fetchData();
    }
  },[messageDetails,isShowRightSidebar])

  async function fetchData() {
    const response = await get(`/api/message/${messageDetails?.id}`);
    if (response.code == 200) {
      setMsgDetails(response.data);
      console.log(response.data);
    } else { 
    console.log(response);
    }
  }

    return (
        <div
          className={`right-sidebar right_sidebar_info ${isShowRightSidebar ? "show-right-sidebar" : "hide-right-sidebar"}`}
          id="middle3"
        >
          <div className="right-sidebar-wrap active">
            <div className="slimscroll">
              <div className="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
                <div className="fav-title mb-0">
                  <h6>
                    <Link to="#" className="remove-message-info" onClick={onCloseSideBar}>
                      <ImageWithBasePath
                        src="assets/img/icon/arrow-left.svg"
                        className="me-2"
                        alt="Icon"
                      />
                    </Link>
                    Messages Info
                  </h6>
                </div>
              </div>
              {msgDetails != null && <div className="sidebar-body chat star-chat-group">
                <div className="chat-body">
                  <div className="messages">
                    <div className="chats">
                    <div className="chat-content">
                    <div className="message-content fancy-msg-box">
                      {msgDetails.file_type == 'file' &&
                      <>
                      {msgDetails.attachments.map((attachment) => (
                        <div className="file-download d-flex align-items-center mb-2" key={'att_file_'+attachment.id}>
                        <div className="file-type d-flex align-items-center justify-content-center me-2">
                          <i className="bx bxs-file" />
                        </div>
                        <div className="file-details">
                          <span className="file-name">{attachment.name}</span>
                          <ul>
                            <li>{Math.round(attachment.size / 1024)} KB</li>
                            <li>
                              <Link to={`${api_base_url}${attachment.file}`} target="_blank" download>Download</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      ))}
                      
                      </>
                     }
                      {msgDetails.file_type == 'image' &&
                        <div className="download-col">
                        <ul className="nav mb-0">
                          {msgDetails.attachments.filter((value,index) => index <= 1).map((attachment) => (
                          <li key={'att_img_'+attachment.id}>
                            <div className="image-download-col">
                              <Link
                                to={`${api_base_url}${attachment.file}`}
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <ImageWithBaseURL
                                  src={`${api_base_url}${attachment.cover_image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                          </li>
                          ))}
                          {msgDetails.attachments.length > 3 && 
                          <li>
                            <div className="image-download-col image-not-download">
                              <Link
                                to="assets/img/media/media-big-01.jpg"
                                data-fancybox="gallery"
                                className="fancybox"
                              >
                                <ImageWithBaseURL
                                  src={`${api_base_url}${msgDetails.attachments[2].cover_image}`}
                                  alt=""
                                />
                                <span>{msgDetails.attachments.length-2}+</span>
                              </Link>
                            </div>
                          </li>}
                        </ul>
                      </div>
                      }
                      {msgDetails.content}
                    </div>
                  </div>
                    </div>
                   {msgDetails?.seen_at != null && <div className="message-info-box">
                      <h6>
                        <span className="material-icons check-active">
                          done_all
                        </span>
                        Read
                      </h6>
                      <span>{moment(localize(msgDetails?.sent_at)).calendar()}</span>
                    </div>}
                    {msgDetails?.delivered_at != null && <div className="message-info-box">
                      <h6>
                        <span className="material-icons check">done_all</span>
                        Delivered
                      </h6>
                      <span>{moment(localize(msgDetails?.delivered_at)).calendar()}</span>
                    </div>}
                    <div className="message-info-box">
                      <h6>
                        <i className="feather-check sent" />
                        Sent
                      </h6>
                      <span>{moment(localize(msgDetails?.sent_at)).calendar()}</span>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
    )
}

export default MessageInfo
