import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Modal } from "react-bootstrap";
import { Content, Conversation, OtherChat, RecentChat } from "../../interfaces/message";
import { get, post } from "../../connections/fetch_wrapper";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";

interface Props {
  // onUpdated: (conversation: Conversation[]) => void;
  show:boolean;
  handleClose: () => void;
  msg:Content | null
}

const ForwardMessage: React.FC<Props> = ({show,handleClose,msg}) => {
  const [recentChat, setRecentChat] = useState<RecentChat[]>([]);
  const [otherChat, setOtherChat] = useState<OtherChat[]>([]);
  const [selectedRecentChat, setSelectedRecentChat] = useState<RecentChat[]>([]); // State for selected contacts
  const [selectedOtherChat, setSelectedOtherChat] = useState<OtherChat[]>([]); // State for selected contacts
  
  useEffect(() => {
    if (show && msg != null) {
      fetchData();
    }
    }, [show,msg]);

  const handleRecentChatCheckboxChange = (contact: RecentChat) => {
      setSelectedRecentChat(prevState => {
          if (prevState.some(prevContact => prevContact.id === contact.id)) {
          return prevState.filter(cont => cont.id !== contact.id); // Deselect the contact if already selected
          } else {
          return [...prevState, contact]; // Select the contact if not already selected
          }
      });
    console.log(selectedRecentChat);
  };

  const handleOtherChatCheckboxChange = (contact: OtherChat) => {
    setSelectedOtherChat(prevState => {
        if (prevState.some(prevContact => prevContact.sid === contact.sid)) {
        return prevState.filter(cont => cont.sid !== contact.sid); // Deselect the contact if already selected
        } else {
        return [...prevState, contact]; // Select the contact if not already selected
        }
    });
    console.log(selectedOtherChat);
  };

  async function fetchData(){
      const response = await get(`/api/conversations/recent-chats`);
      if (response.code == 200) {
        setRecentChat(response.data.data);
        setOtherChat(response.data.outherUsers);
        console.log(response);
      } else { 
      console.log(response);
      }
    }

  function handleCloseModal() {
    handleClose();
  }

  async function sendMsg(){

    const formData = new FormData();
    formData.append('msg_id', msg!.id.toString()); // Add group title
    
    selectedRecentChat.forEach(contact => {
      if (contact.conversation_type == 'GROUP' && contact.gsid != null) {
        formData.append('group_sid[]', contact.gsid ); // Add user IDs
      }else if (contact.sid != null) {
        formData.append('to_user_sid[]', contact.sid ); // Add user IDs
      }
    });

    selectedOtherChat.forEach(contact => {
      formData.append('to_user_sid[]', contact.sid); // Add user IDs
  });
    
      const response = await post(`/api/forward-msg`,formData);
      if (response.code == 200) {
        handleCloseModal();
        console.log(response);
      } else { 
      console.log(response);
      }
  }


  return (
    <>
      {/* Forward Message To */}
      <Modal show={show} onHide={handleCloseModal} dialogClassName="modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Forward Message To</h5>
              <button
                type="button"
                className="close"
                onClick={handleCloseModal}
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="user-block-group mb-4">
                  <div className="search_chat has-search">
                    <span className="fas fa-search form-control-feedback" />
                    <input
                      className="form-control chat_input"
                      id="search-contact"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <h5>Recent Chats</h5>
                  {recentChat.map((conversation) => (
                  <div className="recent-block-group" key={'forward'+conversation.id}>
                    <div className="user-block-profile">
                      <div className="avatar">
                      <ImageWithBaseURL src={conversation.img == '' || conversation.img == null ? `${base_path}assets/img/avatar/${conversation.conversation_type == 'GROUP' ? 'group.webp' : 'profile.jpg'}`:`${api_base_url}/${conversation.img}`} className="rounded-circle" alt="image" />
                      </div>
                      <div className="block-user-name">
                        <h6>{conversation.name}</h6>
                      </div>
                    </div>
                    <div className="notify-check mb-0">
                      <div className="form-check d-flex align-items-center justify-content-start ps-0">
                        <label className="custom-check mt-0 mb-0">
                          <input 
                            type="checkbox"
                            name="remeber" 
                            id={'conv'+conversation.id} 
                            checked={selectedRecentChat.some(prevContact => prevContact.id === conversation.id)} // Check if the contact is selected
                            onChange={() => handleRecentChatCheckboxChange(conversation)} // Call function to toggle selection
                            />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  ))}
                  <h5>Other Chats</h5>
                  {otherChat.map((conversation) => (
                  <div className="recent-block-group" key={'forward'+conversation.id}>
                    <div className="user-block-profile">
                      <div className="avatar">
                      <ImageWithBaseURL src={conversation.img == '' || conversation.img == null ? `${base_path}assets/img/avatar/profile.jpg`:`${api_base_url}/${conversation.img}`} className="rounded-circle" alt="image" />
                      </div>
                      <div className="block-user-name">
                        <h6>{conversation.name}</h6>
                      </div>
                    </div>
                    <div className="notify-check mb-0">
                      <div className="form-check d-flex align-items-center justify-content-start ps-0">
                        <label className="custom-check mt-0 mb-0">
                        <input 
                            type="checkbox"
                            name="remeber" 
                            id={'conv'+conversation.id} 
                            checked={selectedOtherChat.some(prevContact => prevContact.sid === conversation.sid)} // Check if the contact is selected
                            onChange={() => handleOtherChatCheckboxChange(conversation)} // Call function to toggle selection
                            />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
                
              </form>
            </div>
            <div className="modal-footer">
              <div className="mute-chat-btn">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={handleCloseModal}
                    >
                      <i className="feather-x me-1" />
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={sendMsg}
                    >
                      <ImageWithBasePath
                        src="assets/img/icon/send.svg"
                        className="me-1"
                        alt="image"
                      />
                      Send
                    </button>
                  </div>
            </div>
          </div>
        </Modal>
      {/* /Forward Message To */}
    </>
  );
};

export default ForwardMessage;
