import React, { useState } from "react";

import { Link } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import { Conversation } from "../../interfaces/message";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import TimeAgo from 'react-timeago'

interface Props {
    conversations: Conversation[];
    gotoChat: (conversation: Conversation) => void;
    selectedConversation: (conversation: Conversation) => void;

}

const GroupList: React.FC<Props> = ({ conversations,gotoChat,selectedConversation}) => {

  return (
    <div className="sidebar-body chat-body" id="chatsidebar">
                  {/* Left Chat Title */}
                  <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                    <div className="fav-title pin-chat">
                      <h6>Recent Group</h6>
                    </div>
                  </div>
                  {/* /Left Chat Title */}
                  <ul className="user-list">
                  {conversations.filter(conversation => conversation.is_pinned == false).map(filteredConversation => (
                    <li className="user-list-item chat-user-list" key={filteredConversation.id} onClick={()=>gotoChat(filteredConversation)}>
                      <Link to="#" className="status-active">
                        <div className="avatar">
                            <ImageWithBaseURL src={filteredConversation.img == '' || filteredConversation.img == null ? `${base_path}assets/img/avatar/profile.jpg`:`${api_base_url}/${filteredConversation.img}`} className="rounded-circle" alt="image" />
                        </div>
                        <div className="users-list-body">
                          <div>
                            <h5>{filteredConversation.name}</h5>
                            <p>
                            {filteredConversation.sender != null && filteredConversation.sender+' : '}
                              {/* <i className="feather-video ms-1 me-1" /> */}
                              {filteredConversation.content}
                            </p>
                          </div>
                          <div className="last-chat-time">
                            <small className="text-muted"><TimeAgo date={filteredConversation.sent_at} /></small>
                          </div>
                        </div>
                      </Link>
                      <div className="chat-hover">
                        <div className="chat-action-col">
                          <span className="d-flex" data-bs-toggle="dropdown">
                            <i className="bx bx-dots-vertical-rounded" />
                          </span>
                          <div className="dropdown-menu chat-drop-menu dropdown-menu-end">
                            <span className="dropdown-item ">
                              <span>
                                <i className="bx bx-archive-in" />
                              </span>
                              Archive Group{" "}
                            </span>
                            <span
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#mute-notification"
                            >
                              <span>
                                <i className="bx bx-volume-mute" />
                              </span>
                              Mute Notification
                            </span>
                            <span className="dropdown-item">
                              <span>
                                <i className="bx bx-log-out" />
                              </span>
                              Exit Group
                            </span>
                            <span className="dropdown-item">
                              <span>
                                <i className="bx bx-pin" />
                              </span>
                              Pin Group
                            </span>
                            <span className="dropdown-item">
                              <span>
                                <i className="bx bx-check-square" />
                              </span>
                              Mark as Unread
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                  </ul>
                </div>
  );
};

export default GroupList;
