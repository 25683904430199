import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface Props {
  meOnly:boolean;
  show:boolean;
  handleClose: () => void;
  handleSubmit: (val:'me'|'all') => void;
}

const DeleteMessage: React.FC<Props> = ({show,handleClose,handleSubmit,meOnly}) => {
  const [selectedType, setSelectedType] = useState<'me'|'all'>('me'); // State for selected contacts

  useEffect(()=>{
    setSelectedType('me');
  },[show])

  return (
    <>
      {/* Delete Message To */}
    <Modal show={show} onHide={handleClose} dialogClassName="modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content delete-model-head">
            <div className="modal-header">
              <h5 className="modal-title">Delete Message</h5>
              <button
                type="button"
                className="close"
                onClick={handleClose}
              >
                <span className="material-icons">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="notify-check delete-chat-notify">
                <div className="form-check d-flex align-items-center justify-content-start ps-0">
                  <label className="custom-check mt-0 mb-0">
                    <span className="remember-me">Delete for Me</span>
                    <input type="checkbox" name="remeber"
                    checked={selectedType == 'me'}
                    onChange={() => setSelectedType('me')} 
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                {!meOnly && <div className="form-check d-flex align-items-center justify-content-start ps-0">
                  <label className="custom-check mt-0 mb-0">
                    <span className="remember-me">Delete for Everyone</span>
                    <input type="checkbox" name="remeber" 
                    checked={selectedType == 'all'}
                    onChange={() => setSelectedType('all')} 
                    />
                    <span className="checkmark" />
                  </label>
                </div>}
              </div>
              <div className="mute-chat-btn delete-pop-btn justify-content-end">
                <a
                  className="btn btn-secondary"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <i className="feather-x me-1" />
                  Cancel
                </a>
                <a
                  className="btn btn-primary"
                  onClick={()=>{handleSubmit(selectedType)}}
                >
                  <i className="bx bx-trash me-1" />
                  Delete
                </a>
              </div>
            </div>
          </div>
        </Modal>
      {/* /Delete Message To */}
    </>
  );
};

export default DeleteMessage;
