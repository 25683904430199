import React, { useEffect, useState } from "react"
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { get, postFormData } from "../../connections/fetch_wrapper";
import { Contact, MsgResponse } from "../../interfaces/message";
import ImageWithBaseURL from "../../core/data/img/ImageWithBaseURL";
import { api_base_url, base_path } from "../../environment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

interface Props {
  onUpdated: (message: MsgResponse) => void;
  show:boolean;
  handleClose: () => void;
}

  const NewGroup: React.FC<Props> = ({onUpdated,show,handleClose}) => {
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [initialLetters, setInitialLetters] = useState<string[]>([]);
    const [showContact, setShowContact] = useState(false);
    const [groupName, setGroupName] = useState(""); // State for group name
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]); // State for selected contacts

    useEffect(() => {
      if (show) {
        fetchData();
      }
      }, [show]);

    async function fetchData(){
        const response = await get(`/api/users?start=${start}&pagePer=${pageSize}`);
        if (response.code == 200) {
          console.log(response);
          
          setInitialLetters([...new Set(response.data.map(contact => contact.name.charAt(0)))]);
          setContacts(response.data);
        } else { 
        console.log(response);
        }
      }

    function goToContacts(){
        console.log('giiig');
        if (groupName.trim()) {
            setShowContact(!showContact);
        }

        
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedImage = e.target.files ? e.target.files[0] : null;
        setImage(selectedImage);
        if (selectedImage) {
          setImageUrl(URL.createObjectURL(selectedImage)); // Create object URL for preview
        } else {
          setImageUrl(null); // Reset imageUrl if no image selected
        }
      };

    const handleContactCheckboxChange = (contact: Contact) => {
      
        setSelectedContacts(prevState => {
            if (prevState.some(prevContact => prevContact.code === contact.code)) {
            return prevState.filter(cont => cont.code !== contact.code); // Deselect the contact if already selected
            } else {
            return [...prevState, contact]; // Select the contact if not already selected
            }
        });
      console.log(selectedContacts);
    };

    const removeUser = (contact: Contact) =>{
      setSelectedContacts(prevState => {
        return prevState.filter(cont => cont.code !== contact.code); // Deselect the contact if already selected
      });
    }

    async function createGroup(){

      const formData = new FormData();
      formData.append('title', groupName); // Add group title
      if (image) {
          formData.append('img', image);
      }
      
      selectedContacts.forEach(contact => {
          formData.append('user_ids[]', contact.id.toString()); // Add user IDs
      });
      
        const response = await postFormData(`/api/group/stroe`,formData);
        if (response.code == 200) {
          onUpdated(response['data']);
          handleCloseModal();
          console.log(response);
        } else { 
        console.log(response);
        }
    }

    function handleCloseModal() {
      setContacts([]);
      setStart(0);
      setInitialLetters([]);
      setShowContact(false);
      setGroupName("");
      setImage(null);
      setSelectedContacts([]);
      handleClose();
    }

  return (
    <Modal show={show} onHide={handleCloseModal} dialogClassName="modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            New Group
          </h5>
          <button type="button" className="close" onClick={handleCloseModal}>
            <span className="material-icons">close</span>
          </button>
        </div>
        {showContact && <div>
                            <div className="user-block-group mb-4 ps-4 pe-4">
                                <div className="search_chat has-search group-name-search " style={{ position: 'sticky', top: '0', zIndex: '999' }}>
                                    <span className="fas fa-search form-control-feedback" />
                                    <input className="form-control chat_input" id="search-contact-one" type="text" placeholder="Search" />
                                </div>
                            </div>
                            <div className="d-flex text-center flex-wrap ps-3 pe-3 pb-3">
                            {selectedContacts.map(selectContact => (
                                    <div key={'select'+selectContact.code} className="user-list-item pe-1 selected-user-list d-flex">
                                         <div>
                                                {selectContact.name}
                                          </div> 
                                        <Link to="#" onClick={()=>{removeUser(selectContact)}}>
                                          <span className="material-icons">close</span>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>}
        <div className="modal-body">
          <form>
            <div className={`new-group-add  ${showContact ? 'hash-participant':'show-group-add'}`}>
              <div className="user-profiles-group mb-4">
                <div className="profile-cover text-center">
                  <label className="profile-cover-avatar  profile-img" htmlFor="avatar_upload">
                    <ImageWithBasePath className="avatar-img" src={imageUrl != null ? imageUrl: "assets/img/avatar/avatar-2.jpg"} alt="Profile Image" noBaseUrl={true} />
                    <input type="file" id="avatar_upload" onChange={handleImageChange} accept="image/*" />
                    <span className="avatar-edit">
                      <ImageWithBasePath src="assets/img/icon/camera.svg" alt="Image" noBaseUrl={true}/>
                    </span>
                  </label>
                </div>
                <div className="pass-login">
                  <label className="form-label">Group Name </label>
                  <input type="text" className="form-control pass-input" value={groupName} onChange={(e) => setGroupName(e.target.value)}/>
                </div>
              </div>
              
            </div>
            <div className={`parti-group ${showContact ? '':'hash-participant'}`}>
              <div className="user-block-group mb-4">
                {/* <h5>Contacts</h5> */}
                {initialLetters.map(letter => (
                <div className="sidebar" key={letter}>
                  <div className="d-flex justify-content-between align-items-center ps-0 pe-0">
                    <div className="fav-title contact-title" key={'ul'+letter}>
                      <h6>{letter}</h6>
                    </div>
                  </div>
                  <ul className="user-list" key={'u'+letter}>
                  {contacts.filter(contact => contact.name.charAt(0) === letter).map(filteredContact => (
                    <label htmlFor={'user'+filteredContact.code} key={filteredContact.code}>
                    <li className="user-list-item" >
                        <div className="list-user-blk">
                            <div className="avatar">
                            <ImageWithBaseURL src={filteredContact.image == '' || filteredContact.image == null ? `${base_path}assets/img/avatar/profile.jpg`:`${api_base_url}/${filteredContact.image}`} className="rounded-circle" alt="image" />
                            </div>
                            <div className="users-list-body">
                              <div>
                                <h5>{filteredContact.name}</h5>
                                <p>{filteredContact.email}</p>
                              </div>    
                            </div>
                            <div className="notify-check parti-notify-check" >
                              <div className="form-check d-flex align-items-center justify-content-start ps-0">
                                <label className="custom-check mt-0 mb-0">
                                  <input 
                                    type="checkbox" 
                                    name="remeber"
                                    id={'user'+filteredContact.code} 
                                    checked={selectedContacts.some(prevContact => prevContact.code === filteredContact.code)} // Check if the contact is selected
                                    onChange={() => handleContactCheckboxChange(filteredContact)} // Call function to toggle selection
                                />                                        
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>  
                        </div> 
                    </li>
                    </label>                                       
                  ))}
                  </ul>                  
                </div>
                ))}
              </div>
            </div>
          </form>
        </div>  
        <div className="modal-footer">
        {showContact ? 
            <div className="mute-chat-btn">                            
                <a className="btn btn-secondary" id="previous-group" onClick={()=>{goToContacts()}}>
                <i className="bx bx-left-arrow-alt me-1" />Previous
                </a>
                <a className="btn btn-primary"  onClick={()=>{createGroup()}}>
                <i className="bx bx-send me-1" />Create 
                </a>
            </div>
            :
            <div className="mute-chat-btn">
                <a className="btn btn-secondary" onClick={handleCloseModal}>
                    <i className="feather-x me-1" />Cancel
                </a>
                <a className="btn btn-primary" id="next-parti" onClick={()=>{goToContacts()}}>
                    <i className="feather-arrow-right me-1" />Next
                </a>
            </div>
        }
      </div>
      </div>
  </Modal>
  )
}

export default NewGroup