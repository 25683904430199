import React from "react";
import { Route } from "react-router";
import { all_routes } from "./all_routes";
import Contact from "../contact/contact";
import Group from "../group/group";
import Settings from "../settings.tsx";


import Call from "../call/call";
import AudioCall from "../audio-call/audioCall";
import VideoCall from "../video-call/videoCall";
import CallGrid from "../call-grid/callGrid";

import EmptyStatus from "../status/emptyStatus";
import ViewStatus from "../status/viewStatus";
import Status from "../status/status";
import StatusOther from "../status/statusOther";
import AllChat from "../chat/all-chat";
import PinnedChat from "../chat/pinned-chat";
import ArchiveChat from "../chat/archive-chat";
import Index from "../chat";
import Login from "../authentication/login/login";

const routes = all_routes;

export const publicRoutes = [
  {
    path: routes.index,
    element: <Index />,
    route: Route,
  },
  {
    path: routes.contact,
    element: <Contact />,
    route: Route,
  },
  {
    path: routes.group,
    element: <Group />,
    route: Route,
  },
  {
    path: routes.settings,
    element: <Settings />,
    route: Route,
  },
  {
    path: routes.emptyStatus,
    element: <EmptyStatus />,
    route: Route,
  },
  {
    path: routes.call,
    element: <Call />,
    route: Route,
  },
  {
    path: routes.audioCall,
    element: <AudioCall />,
    route: Route,
  },
  {
    path: routes.videoCall,
    element: <VideoCall />,
    route: Route,
  },
  {
    path: routes.callGrid,
    element: <CallGrid />,
    route: Route,
  },
  {
    path: routes.viewStatus,
    element: <ViewStatus />,
    route: Route,
  },
  {
    path: routes.status,
    element: <Status />,
    route: Route,
  },
  {
    path: routes.statusOther,
    element: <StatusOther />,
    route: Route,
  },
  {
    path: routes.pinnedChat,
    element: <PinnedChat />,
    route: Route,
  },
  {
    path: routes.archiveChat,
    element: <ArchiveChat />,
    route: Route,
  },
  {
    path: routes.allChat,
    element: <AllChat />,
    route: Route,
  },
  {
    path: routes.login,
    element: <Login />,
    route: Route,
  }
];