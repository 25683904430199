import axios from "axios";
import { getToken } from "../helpers/helpers";
import { IApiResponse } from "../interfaces/manage/IApiResponse";
import {axiosInstance,axiosInstanceFormData} from "./api_connection";

export async function get(url: string) {
    const response = await axiosInstance.get(url).then(function (response) {
        console.log(response);
        const data: IApiResponse = {
            msg: response.data['msg'],
            code: 200,
            data: response.data['data'],
            error: null,
            success: true,
            count:response.data['count'] ?? 0
        }
        return data;
    }).catch(function (error) {
        const data: IApiResponse = {
            msg: error.response.data.message,
            code: error.response.status,
            data: null,
            error: error.response.data,
            success: false
        }
        return data;
    });
    return response;
}

export async function post(url: string, body: any) {
    console.log('process.env.BASE_URL',process.env.BASE_URL);
    
    const response = await axiosInstance.post(url, body).then(function (response) {
        console.log(response);
        const data: IApiResponse = {
            msg: response.data['msg'],
            code: 200,
            data: response.data['data'],
            error: null,
            success: true,
            count:response.data['count']
        }
        return data;
    }).catch(function (error) {
        const data: IApiResponse = {
            msg: error.response.data['error'],
            code: error.response.status,
            data: null,
            error: error.response.data.error,
            success: false
        }
        return data;

    });
    return response;
}
export async function postFormData(url: string, body: any) {
    const response = await axiosInstanceFormData.post(url, body).then(function (response) {
        const data: IApiResponse = {
            msg: response.data['message'],
            code: 200,
            data: response.data['data'],
            error: null,
            success: true,
            count:response.data['count']
        }
        return data;
    }).catch(function (error) {
        const data: IApiResponse = {
            msg: error.response.data.message,
            code: error.response.status,
            data: null,
            error: error.response.data.error,
            success: false
            
        }
        return data;

    });
    return response;
}


export async function put(url: string, body: any) {
    const response = await axiosInstance.put(url, body).then(function (response) {
        const data: IApiResponse = {
            msg: response.data['message'],
            code: 200,
            data: response.data['data'],
            error: null,
            success: true,
            count:response.data['count']
        }
        return data;
    }).catch(function (error) {
        const data: IApiResponse = {
            msg: error.response.data.message,
            code: error.response.status,
            data: null,
            error: error.response.data,
            success: false
        }
        return data;
    });
    return response;
}

export async function deleteMethod(url: string) {
    const response = await axiosInstance.delete(url).then(function (response) {
        const data: IApiResponse = {
            msg: response.data['message'],
            code: 200,
            data: response.data['data'],
            error: null,
            success: true,
            count:response.data['count']
        }
        return data;
    }).catch(function (error) {
        const data: IApiResponse = {
            msg: error.response.data.message,
            code: error.response.status,
            data: null,
            error: error.response.data,
            success: false
        }
        return data;
    });
    return response;
}